<template>
  <div>
       <b-modal
      id="modal-select2"
      title="Choose Services"
      centered
      size="sm"
      no-close-on-backdrop
      hide-footer
    >

     <div>
      <b-form-group label="">
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="selected"
        name="flavour-2"
       style="margin-left:1px;"
      >
      <b-row class="mt-1">
        <b-col>
<b-form-checkbox value="Consultation service">
           Consultation service
        </b-form-checkbox>
        </b-col>
      </b-row>
        <b-row class="mt-1">
        <b-col>
   <b-form-checkbox value="Products selection and designs">
            Products selection and designs
        </b-form-checkbox>
        </b-col>
     
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
<b-form-checkbox value="Models services">
            Models services
        </b-form-checkbox>
          </b-col>

        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
<b-form-checkbox value="Photography">
            Photography
        </b-form-checkbox>
          </b-col>

        </b-row>
<b-row class="mt-1">
  <b-col>
<b-form-checkbox value="Branding">
            Branding
        </b-form-checkbox>
  </b-col>
   
</b-row>
        
     
      </b-form-checkbox-group>
    </b-form-group>
               </div>
               <div class="text-right">
 <b-button class="mr-1 mt-3" @click="Skip()" > Skip</b-button>
 <b-button class=" mt-3"  @click="NextPopup()" variant="primary" >Ok</b-button>
               </div>

     
    </b-modal>
    <form-wizard
      color="#f88379"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="FinishButton"
      back-button-text="Previous"
      class="mb-3"
      ref="wizard"
      @on-complete="formSubmitted"
    >
   <!--  Logo must be with white background & 100 x 100 pixels -->
    <!-- :before-change="validationForm" -->
      <tab-content title="Account Details" :before-change="validationForm">
        <b-row>
          <b-col cols="12" class="mb-2">
            <small class="text-muted">
              Enter Your Account Details.
            </small>
          </b-col>
          <b-col md="6">
            <b-form-group label="First name" label-for="name">
              <b-form-input
                id="firstName"
                v-model="vendorObj.user.firstName"
                ref="firstName"
                placeholder="Enter your first name here"
                @focusout="checkFirstName()"
              />
              <small class="text-danger" v-if="errors.firstName == true"
                >Please enter first name.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Last name" label-for="name">
              <b-form-input
                id="lastName"
                v-model="vendorObj.user.lastName"
                ref="lastName"
                placeholder="Enter your last name here"
                @focusout="checkLastName()"
              />
              <small class="text-danger" v-if="errors.lastName == true"
                >Please enter last name.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="vendorObj.user.email"
                placeholder="Enter your email here"
                ref="email"
                @focusout="checkEmail()"
              />
              <small class="text-danger" v-if="errors.email == true"
                >Please enter correct email.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                  :state="nameState"
                  label="Phone number" 
                  label-for="phone"
                  invalid-feedback="Phone number is required."
                  ref="phone"
                  required
                >
                  <the-mask
                    class="form-control"
                    placeholder="971 58 123 4567"
                    v-model="vendorObj.user.phoneNo"
                    :mask="['971 ## ### ####']"
                  />
                </b-form-group>
            <!-- <b-form-group label="Phone number" label-for="email">
              <b-form-input
                id="phoneNumber"
                v-model="vendorObj.user.phoneNo"
                placeholder="Enter your phone number here"
                ref="phoneNumber"
              />
              <small class="text-danger" v-if="errors.phoneNumber == true"
                >Please enter phone number.</small
              >
            </b-form-group> -->
          </b-col>
          <b-col md="6">
            <b-form-group label="Password" label-for="password">
              <b-form-input
                id="password"
                v-model="vendorObj.user.password"
                type="password"
                ref="password"
                @focusout="checkPassword()"
                placeholder="Password"
              />

              <small class="text-danger" v-if="errors.password == true"
                >Sholud contain 8 characters with 3 numeric digits and 1 capital
                alphabet.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Confirm password" label-for="c-password">
              <b-form-input
                id="c-password"
                v-model="passwordCon"
                type="password"
                ref="cpassword"
                placeholder="Re-type Password"
                @focusout="checkCPassword()"
              />
              <small class="text-danger" v-if="errors.conpassword == true"
                >Password does not match.</small
              >
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Preference">
              <v-select
                id="preference"
                v-model="vendorObj.vendor.preferences"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="types"
                label="name"
                placeholder="Select preferences"
                @search:blur="CheckPreferences()"
              />
              <small class="text-dark">{{this.tooltip}}</small>
              <small class="text-danger" v-if="errors.preference == true"
                >Preference is required.</small
              >
              
            </b-form-group>
          </b-col>
        </b-row>

        <div></div>
      </tab-content>
<!-- :before-change="validationFormBakeryDetails" -->
      <tab-content  :before-change="validationFormBakeryDetails"
        title="Business Details"  >
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Business Details
                </h5>
                <small class="text-muted">Enter your business details.</small>
              </b-col>
              <b-col md="12">
                <b-form-group label="Business Name" label-for="bakery_name">
                  <b-form-input
                    id="businessName"
                    v-model="vendorObj.vendor.business"
                    placeholder="Enter business name"
                    ref="businessName"
                    @focusout="CheckBusinessName()"
                  />
                  <small class="text-danger" v-if="errors.businessName == true"
                    >Please enter business name.</small
                  >
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group  label-for="profile_picture">
                  <label> Logo <span v-if="vendorObj.vendor.preferences.isFor == 'Both'">(optional)</span></label>
                  <b-media no-body>
                    <div v-if="logoloading == true">
                      <b-spinner
                        variant="primary"
                        type="grow"
                        class="m-4"
                        label="Spinning"
                      ></b-spinner>
                    </div>

                    <b-media-aside>
                      <b-link>
                        <b-img
                          ref="previewEl"
                          rounded
                          src="https://dk0pm9zdlq16s.cloudfront.net/19fd5f70-b269-499e-a127-2d1767a7a182.png"
                          @click="$refs.fileProfile.click()"
                          v-if="logoloading == false"
                          thumbnail
                          fluid
                          height="110px"
                          width="110px"
                        />

                        <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
                      </b-link>

                      <b-link v-if="logoloading == 'loaded'">
                        <div>
                          <i
                            @click="deleteLogo()"
                            class="fa fa-trash fa-sm text-danger position-absolute m-4"
                          ></i>
                        </div>
                        <b-img
                          rounded
                          :src="plus"
                          height="110px"
                          width="110px"
                        />
                        <br />
                      </b-link>

                      <input
                        type="file"
                        id="fileProfile"
                        hidden
                        ref="fileProfile"
                        @change="selectlogo()"
                        accept="image/*"
                      />
                    </b-media-aside>
                  </b-media>

                  <!-- <input
                         type="file"
                        id="fileProfile"
                        hidden
                        ref="fileProfile"
                        @change="selectlogo()"
                        accept="image/*"  />

                         <div><b-img thumbnail fluid rounded v-if= "logoloading==false" :src="plus" @click="$refs.fileProfile.click()" style="width: 7vw; height: 7vw; " alt="imgAdd"/></div>
                           <div v-if= "logoloading==true" class="text-center" > <i class="fas fa-circle-notch fa-spin fa-2x" style="color:grey"></i>
                           </div> -->
                  <small class="text-muted"
                    >Logo must be with white background & 100 x 100 pixels</small
                  >
                  <br />
                  <small class="text-danger" v-if="errors.profile == true"
                    >Please select logo picture.</small
                  >
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="About Us" label-for="aboutUs"
                    ref="about"
                >
                  <textarea
                    rows="4"
                    class="form-control"
                    id="aboutUs"
                    v-model="vendorObj.vendor.aboutUs"
                    placeholder="Enter the details about your business"
                    ref="about"
                    @keydown="CheckAbout()"
                  />

                  <small class="text-danger" v-if="errors.about"
                    >Please enter details about your business.</small
                  >
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Address" label-for="address">
                  <b-form-input
                    id="address"
                    v-model="vendorObj.vendor.address"
                    placeholder="Enter business address"
                    ref="address"
                    @focusout="CheckAddress()"
                  />

                  <small class="text-danger" v-if="errors.address == true"
                    >Please enter address of your business.</small
                  >
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="City" label-for="City" ref="city">
                  <v-select
                    id="city"
                    v-model="vendorObj.vendor.city"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="cityName"
                    label="text"
                    placeholder="Select city"
                    @search:blur="CheckCity()"
                  />
                  <!-- :options:deselecting="CheckCity1()" -->
                  <small class="text-danger" v-if="errors.city == true"
                    >Please select city.</small
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <!-- <br><br><br> -->
            <!-- <b-form-group
                label="Location"
                label-for="location"
                >
                </b-form-group>
                <b-img :src= "require('@/assets/images/elements/map.jpg')" fluid > </b-img> -->
          </b-col>
        </b-row>
      </tab-content>
<!-- :before-change="validationDocs" -->
      <tab-content title="Upload Documents" :before-change="validationDocs" >
        <b-row>
          <b-col cols="12" class="mb-2"> </b-col>
          <b-col lg="6">
            <h5>Upload Emirates ID / Passport <span v-if="vendorObj.vendor.preferences.reqDocs == 'TradeLicense' "> (optional) </span></h5> 
            <ul>
              <li>
                <small class="text-muted"
                  >Upload the front side of emirates id's image
                </small>
              </li>
              <li>
                <small class="text-muted"
                  >Upload the backside side of emirates id's image
                </small>
              </li>
            </ul>

            <b-form-group>
              <!-- <validation-provider
                  #default="{ errors }"
                  name="Add picture"
                  rules="required"
                > -->
              <div class="container">
                <b-row>
                  <div v-for="(item, index) in vendorObj.ids" :key="index">
                    <b-col>
                      <div
                       style="box-shadow:3px 7px #e9e4e5; border-radius:8px; width: 120px; height: 120px; padding: 3px "
                      >
                        <b-row>
                          <b-col md="10"></b-col>
                          <b-col md="0">
                            <feather-icon
                              icon="Trash2Icon"
                              size="11"
                              color="#FF5A60"
                              @click="removeEmirateId(index)"
                            />
                          </b-col>
                        </b-row>
                        <b-img :src="item"  width="110" height="93" />
                      </div>
                    </b-col>
                  </div>
                  <div>
                    <img
                      v-if="!idloading"
                      :src="idCard"
                      @click="$refs.EmpId.click()"
                      style="width: 110px; height: 110px; padding-left:20px; padding-top:20px;"
                      alt="imgAdd"
                    />
                    <input
                      type="file"
                      id="EmpId"
                      hidden
                      ref="EmpId"
                      multiple="multiple"
                      @change="EmeratesId()"
                      accept="image/*"
                    />
                  </div>
                  <div
                    v-if="idloading"
                    class="text-center"
                    style="margin-left:40px; margin-top:50px;"
                  >
                    <i
                      class="fas fa-circle-notch fa-spin fa-2x"
                      style="color:grey"
                    ></i>
                  </div>
                </b-row>
              </div>

              <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
            <small style="margin-left:20px;" class="text-danger" v-if="errors.emiratesids == true"
                >Please upload your emirates id.</small
              >
                
            </b-form-group>
            
            <h5>Upload Trade License  <span v-if="vendorObj.vendor.preferences.reqDocs == 'EmiratesId/Passport' "> (optional) </span></h5>
            <ul>
              <li>
                <small class="text-muted"
                  >Upload the front side of trade license's image
                </small>
              </li>
              <li>
                <small class="text-muted"
                  >Upload the backside side of trade license's image
                </small>
              </li>
            </ul>

            <b-form-group>
              <!-- <validation-provider
                  #default="{ errors }"
                  name="Add picture"
                  rules="required"
                > -->
              <div class="container">
                <b-row>
                  <div
                    v-for="(idImage, index) in vendorObj.licenses"
                    :key="idImage"
                  >
                    <b-col>
                      <div
                        style="box-shadow:3px 7px #e9e4e5; border-radius:8px; width: 120px; height: 120px; padding: 3px "
                      >
                        <b-row>
                          <b-col md="10"></b-col>
                          <b-col md="0">
                            <feather-icon
                              icon="Trash2Icon"
                              size="11"
                              color="#FF5A60"
                              @click="removeLicense(index)"
                            />
                          </b-col>
                        </b-row>
                        <b-img :src="idImage" width="110" height="93" />
                      </div>
                    </b-col>
                  </div>
                  <div v-if="licenseloading == false">
                    <img
                      :src="license"
                      @click="$refs.licensefile.click()"
                      style="width: 110px; height: 110px; padding-left:20px; padding-top:20px;"
                      alt="imgAdd"
                    />
                  </div>

                  <div
                    v-if="licenseloading == true"
                    class="text-center"
                    style="margin-left:40px; margin-top:50px;"
                  >
                    <i
                      class="fas fa-circle-notch fa-spin fa-2x"
                      style="color:grey"
                    ></i>
                  </div>
                </b-row>
              </div>
              <input
                type="file"
                id="licensefile"
                hidden
                ref="licensefile"
                multiple
                @change="TLicense()"
                accept="image/*"
              />
              <!-- <div >
                  <UploadImages />
                </div> -->
              <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
            
            </b-form-group>
              <small style="margin-left:20px;" class="text-danger" v-if="errors.tradelicenses == true"
                >Please upload trade license.</small
              >
            <!-- <br /> -->
            
          </b-col>
          
        </b-row>
      </tab-content>
<!-- :before-change="validationPackage" -->

      <tab-content title="Choose Package" :before-change="validationPackage" >
        <b-container class="bv-example-row">
          <b-row align-h="center">
            <h1 class="pb-3">Choose Package</h1>
          </b-row>
          <b-row align-h="center">
            <b-col lg="3" md="4">
              <a>
                <b-card
                  @click="ClickAble1()"
                  tag="article"
                  style="max-width: 20rem;border-radius: 25px;"
                  class="text-center initial"
                  v-bind:class="{ clickcard: active1 }"
                >
                  <b-avatar
                    size="70px"
                    :variant="activeAvatar1"
                    text="B"
                    v-bind:class="{ clickcard: active1 }"
                  >
                  </b-avatar>
                  <b-card-title
                    class="mt-2 initial"
                    v-bind:class="{ clickcard: active1 }"
                    >Bronze Package</b-card-title
                  >
                  <b-card-text>
                    The advertisement period is 1 month ( 5 products ) on Brand
                    away application, Ad format (app main page) For free.
                  </b-card-text>
                  <hr />
                  <b-card-text>
                    <strong> Free </strong> 5 products
                  </b-card-text>
                </b-card>
              </a>
            </b-col>

            <b-col lg="3" md="4">
              <a>
                <b-card
                  @click="ClickAble2()"
                  tag="article"
                  style="max-width: 20rem;border-radius: 25px;"
                  class="text-center initial"
                  v-bind:class="{ clickcard: active2 }"
                >
                  <b-avatar
                    size="70px"
                    :variant="activeAvatar2"
                    text="S"
                    v-bind:class="{ clickcard: active2 }"
                  >
                  </b-avatar>
                  <b-card-title
                    class="mt-2 initial"
                    v-bind:class="{ clickcard: active2 }"
                    >Silver package</b-card-title
                  >
                  <b-card-text>
                    The advertisement period is 6 month on Brand away
                    application Ad format (app main page) For AED 500 per
                    month.
                  </b-card-text>
                  <hr />
                  <b-card-text>
                    <strong> 6 Months </strong>
                  </b-card-text>
                </b-card>
              </a>
            </b-col>

            <b-col lg="3" md="4">
              <a>
                <b-card
                  @click="ClickAble3()"
                  tag="article"
                  style="max-width: 20rem;border-radius: 25px;"
                  class="text-center initial"
                  v-bind:class="{ clickcard: active3 }"
                >
                  <b-avatar
                    size="70px"
                    :variant="activeAvatar3"
                    text="G"
                    v-bind:class="{ clickcard: active3 }"
                  >
                  </b-avatar>
                  <b-card-title
                    class="mt-2 initial"
                    v-bind:class="{ clickcard: active3 }"
                    >Gold Package</b-card-title
                  >
                  <b-card-text>
                    The advertisement period is one year on Brand away
                    application Ad format (app main page) For AED 1000 per
                    month.
                  </b-card-text>
                  <hr />
                  <b-card-text>
                    <strong> 1 Year </strong> 
                  </b-card-text>
                </b-card>
              </a>
            </b-col>

            <!-- <b-col lg="3" md="12">  
        <b-card title="About Packages" header-tag="header" footer-tag="footer">
      <hr>
      <h3>Bronze</h3>
      <b-card-text> The advertisement period is 1 month ( 5 products ) on Brand away application, Ad format (app main page) For free.</b-card-text>
      
      <br>

    <h3>Sliver</h3>
      <b-card-text>The advertisement period is 6 months on Brand away application Ad format (app main page) For 500 dirhams per month & 10% of each product sale to Brandaway. in addition to 5 banner advertisements at the top of the application for 3 seconds, during the agreement period without the peak period.</b-card-text>
    
      <br>

     <h3>Gold</h3>
      <b-card-text> The advertisement period is one year on Brand away application Ad format (app main page) For 1000 dirhams per month and 7.5 % of each product sale to Brandaway. , in addition to 10 banner advertisements at the top of the application for 3 seconds, during the agreement period without the peak period.</b-card-text>
    
    </b-card>
          </b-col> -->
          </b-row>
        </b-container>
      </tab-content>
       <!-- :before-change="ValidateContract" -->
       <!-- :before-change="signatureCheck"
        -->
      <tab-content title="Contact Terms" :before-change="signatureCheck">
        <b-row>
          <b-col></b-col>
          <b-col> <h1 class="text-center">Agreement Contract</h1> </b-col>
          <b-col></b-col>
        </b-row>
        <b-row>
          <b-col cols="3"></b-col>
          <b-col cols="6">
            Date: {{ this.currentDate }} <br /><br />
            Contracted between: <br />
            Brandaway It is referred to later as the first party And between
            <br />
            Name:
            {{
              this.vendorObj.user.firstName + ' ' + this.vendorObj.user.lastName
            }}
            The name of the supplier - the services - the shop:
            {{ this.vendorObj.vendor.business }}<br />
            Phone: {{ this.vendorObj.user.phoneNo }}
            <span class="ml-1">
              Address : {{ this.vendorObj.vendor.address }}
            </span>
            <br /><br />
            <p>
              The first team owns the commercial license of Brand away
              application The activities are electronic trading activity through
              websites and electronic trading through social media, import,
              general trade, and advertisements on Brand away application.
            </p>
            <strong> 1. Our Services </strong> <br />
            <br />
            <p>
              2.1 We provide Services for you as a seller to enable you to offer
              your products/services to buyers registered on the Brandaway App.
              Depending on the business model, our Services, provided by us, may
              include, (a) order management and fulfilment (e.g. communication
              with a buyer on his/her purchase, obtaining payment from a buyer
              and organizing delivery); (b) shipping the product from your
              premises to the buyer; (c) cash collection and processing of
              payments; and (d) call centre and customer support (e) marketing
              and advertising your products and services on our platform as per
              our Terms of Use and our Terms of Sale.
              <br /><br />
              2.2 We reserve the right to determine the content, appearance,
              design, functionality and all other aspects of the App and the
              Services (including the right to re-design, modify, remove and
              alter the content, appearance, design, functionality, and other
              aspects of the App and the Service and any element, aspect,
              portion or feature thereof, from time to time), and to delay or
              suspend listing of, or to refuse to list, or to de-list, or to
              require you not to list, any or all products in our sole
              discretion.
            </p>
            <strong> 3. Business Models </strong> <br />
            <br />
            <p>
              We reserve the right, in accordance with the seller performance
              program, to suspend or block your account for, (a) your delivery
              delays against agreed timeline; b) a high level of returns; c)
              negative reviews from buyers; and/or (d) cancellation of a
              confirmed order by a buyer. Furthermore, we reserve the right to
              hold the products and transfer the ownership of goods to us, if
              you fail to pay the dues within thirty (30) days from the date of
              dues outstanding. (e) Deliver products in accordance with the
              packaging requirements and other instructions we notify you of in
              writing in advance;(f) package and transport products safely and
              in such a manner that minimizes the risk of damage to the
              product;(g) obtain all necessary documentation, permits and
              consents to deliver the product; (h) arrange pick-up of products
              that are the subject of a return, replacement or exchange request
              or are otherwise rejected by us at the quality check stage; (i)
              remain responsible for after-sales services, guarantees and
              maintenances and defects
            </p>
            <br />
            <p>
              You accept that you may be charged an amount up to 100% of the
              price of a product, where you refuse to accept and fulfill an
              order for the following reasons, including but not limited to:
              <br /><br />
              (a) the product is out of stock on your side, despite being listed
              with stock on your seller account; or was listed and live on the
              Site but is out of stock; and <br /><br />
              (b) you submitted a wrong product price on your account.
              <br /><br />
              (c) Sale Proceeds will be paid to your bank account on a weekly
              occurrence. Sales Proceeds can be credited only to bank accounts
              in the UAE or any other country shown on the Site as supported by
              our standard functionality and enabled for your account. We shall
              not be liable for any incorrect bank account details provided by
              you, and the consequences thereof.<br /><br />
              (d) You agree that we may choose to offer discounts against your
              products. In such instance, we will include a discount on the
              invoice raised from you to the buyer and will pay for this
              discount by adjusting our commission.<br /><br />
              It is referred to later as the second party
            </p>
            <br />
            <br />
            <strong>4.Reboot </strong>
            <br />
            <br />
            <p>
              The first team owns the commercial license of Brand away
              application The activities are electronic trading activity through
              websites and electronic trading through social media, import,
              general trade, and advertisements on Brand away application.
            </p>

            <br />
            Whereas the second party is working in the field trading or other
            services for customers or advertising for their products through
            Brand away Therefore, the two parties met to conclude this contract
            where they agreed on the following:
            <br /><br />
            <strong> The First clause - </strong>
            Under this contract, the first party agreed to place a promotional
            electronic advertisement on the services of the second party on
            brandaway application according to the following packages:
            <br /><br />
            <strong> Bronze Package: </strong>
            The advertisement period is 1 month ( 5 products ) on Brand away
            application, Ad format (app main page) For free
            <br /><br />
            <strong> Silver package: </strong>
            The advertisement period is 6 months on Brand away application Ad
            format (app main page) For 500 dirhams per month & 10% of each
            product sale to Brandaway in addition to 5 banner advertisements at
            the top of the application for 3 seconds, during the agreement
            period without the peak period
            <br /><br />
            <strong> Golden Package: </strong>
            The advertisement period is one year on Brand away application Ad
            format (app main page) For 1000 dirhams per month and 7.5 % of each
            product sale to Brandaway , in addition to 10 banner advertisements
            at the top of the application for 3 seconds, during the agreement
            period without the peak period
            <br /><br />
            In addition, Brandaway gives way to additional promotional support,
            as follows:
            <br /><br />
            <strong> The first fixed advertisement package:</strong>

            the advertisement period is 3 months
            <br /><br />
            Ad format (fixed on homepage) 5 times for 3 seconds without peak
            period.
            <br /><br />
            For 10,000 dirhams Advertising space.......

            <br /><br />
            <strong> The second fixed advertisement package:</strong>
            the advertisement period is 6 months
            <br /><br />
            Ad format (fixed on homepage) 10 times for 3 seconds without peak
            period.
            <br /><br />
            For 15,000 dirhams Advertising space.......
            <br /><br />
            <strong> The third fixed advertisement package:</strong>
            the advertisement period is 1 year.
            <br /><br />
            Ad format (fixed on homepage) 20 times for 3 seconds without peak
            period. For 25,000 dirhams Advertising space ...
            <br /><br />
            <strong> The Second clause-</strong>

            It is the responsibility of the second party to provide the required
            information , products and images with the support from Brandaway
            consultant
            <br /><br />
            <strong> The third clause -</strong>
            The first party is obligated to provide the second party with all
            the services required for visitors in terms of patient counting and
            all other information.
            <br /><br />
            <strong> The forth clause - </strong>
            The first party is not responsible for the quality of services
            provided by the second party and the extent of customer satisfaction
            with it.
            <br /><br />
            <strong> The fifth clause – </strong>
            The second party should not deal directly with customers or contact
            them, any sale information though Brandaway app will be through the
            app chat.
            <br /><br />
            <strong> The sixth clause - </strong>
            The sixth clause - The two parties relied on the notification and
            announcement between them by e-mail approved in this contract.
            <br /><br />
            <strong> The seventh clause - </strong>
            If this contract is terminated or terminated by one of the parties
            at an inappropriate time and for an unlawful reason and such
            termination would cause a loss to the other party, then the party
            causing this termination shall be obligated to compensate the
            aggrieved party for the loss it suffered and the jurisdiction for
            the courts of the Emirate of Abu Dhabi to look in it.
            <br /><br />
            <strong> The eighth clause - </strong>
            The second party will abide by the regulations, laws and directives
            in force in the United Arab Emirates with regard to the content of
            the advertisement, observance of public morals and avoiding sexual
            and pornographic suggestions in the materials required to be
            displayed in the advertisement. The ninth Clause - The first party
            has the right to stop displaying the advertisement on Brandaway
            application when the second party stops paying its value.
            <br /><br />
            <strong> The ninth clause - </strong>
            The first party has the right to stop displaying the advertisement
            on Brandaway application when the second party stops paying its
            value.
            <br /><br />
            <strong> The tenth clause - </strong>
            The tenth clause - The second party is obliged to pay the full value
            of the declaration upon signing the contract with the first party.
            <br /><br />
            
             
            
  
          </b-col>
          <b-col cols="3"></b-col>
        </b-row>
        <b-row>
          <b-col cols="3"></b-col>
          <b-col>
  <div>
    <strong>Choose Services </strong>
      <b-form-group label="">
      <b-form-checkbox-group
            class=" demo-inline-spacing"
        id="checkbox-group-2"
        v-model="selected"
        name="flavour-2"
       style="margin-left:1px;"
      >
     
<b-form-checkbox value="Consultation service">
           Consultation service
        </b-form-checkbox>
         <b-form-checkbox value="Products selection and designs">
            Products selection and designs
        </b-form-checkbox>
      
        
    <b-form-checkbox value="Models services">
            Models services
        </b-form-checkbox>
       
    <b-form-checkbox value="Photography">
            Photography
        </b-form-checkbox>
          
        <b-form-checkbox value="Branding">
            Branding
        </b-form-checkbox>
  
        
     
      </b-form-checkbox-group>
    </b-form-group>
               </div>
          </b-col>
          <b-col cols="3"></b-col>
        </b-row>
      
        <b-container class="mt-1 bv-example-row text-center">
          <b-row align-h="center">
            <b-col cols="4" class="card text-center">
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col cols="8">
                  <vueSignature
                    ref="signature"
                    :sigOption="option"
                    :w="'300px'"
                    :h="'300px'"
                    :disabled="disabled"
                  ></vueSignature>
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>

              <b-button hidden @click="save">Save</b-button>
              <b-button @click="clear">Clear</b-button>
              <b-button hidden @click="undo">Undo</b-button>
              <!-- <b-button @click="handleDisabled">disabled</b-button> -->
            </b-col>
          </b-row>
        </b-container>
     
      </tab-content>
       <tab-content title="Make Payment" v-if="paymentScreen">
      <div class="misc-wrapper">
    <!-- <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Okaaik
      </h2>
    </b-link> -->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 >
          Please make the payment to proceed with your account.
        </h2>
       <b-row  class="justify-content-md-center mt-3" >
        
         <b-col xl="6" lg="6" md="8" cols="12">
    <b-tabs pills nav-class="nav-pill-primary">
      <b-tab title="Yearly" @click="ChangePrice(amount,'yearly')" active/>
      <b-tab title="Monthly" @click="ChangePrice(amount,'monthly')" />

    
    </b-tabs>
           <h3 class="text-primary"> AED {{this.amount}}  </h3>
        <stripe-element-card
          class="mt-2"
          ref="elementRef"
          :pk="pubKey"
          @token="tokenCreated"
        />
           <b-button  :disabled="this.busy" block class="mt-2" @click="paymentBtn()"  variant="primary" >
          Pay Now
        </b-button>
         </b-col>
       
      </b-row>
     

        <!-- <b-img fluid :src="imgUrl" alt="Coming soon page" /> -->
      </div>
    </div>
  </div>
     
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import axios from 'axios';
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormSpinbutton,
  BFormFile,
  BButton,
  BSpinner,
  BContainer,
  BCard,
  BFormCheckboxGroup,
  BCardTitle,
  BCardText,
  BAvatar,
  BFormCheckbox,
  BModal ,
  BTabs,
  BTab
} from 'bootstrap-vue';
import { required, email, url } from '@validations';
import moment from 'moment';
import { codeIcon } from './code';
import flatPickr from 'vue-flatpickr-component';
import flatPicker from 'vue-flatpickr-component';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import vueSignature from 'vue-signature';
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { TheMask } from 'vue-the-mask';
//import UploadImages from "vue-upload-drop-images"
export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    vueSignature,
    FormWizard,
    TabContent,
    TheMask,
    BRow,
    StripeElementCard,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardTitle,
    BFormCheckboxGroup,
    BCardText,
    BButton,
    BContainer,
    BMedia,
    BMediaAside,
    BSpinner,
    BCard,
    BAvatar,
    BFormCheckbox,
    BModal,
    BTabs,
    BTab,
    //BContainer,
    //BMediaBody,
    // BFormFile,
    // //ToastificationContent,
    BImg,
    //flatPickr,
    //flatPicker,
    //BCard,
  },
  directives: {
    Ripple,
  },

  created() {
    var axios = require('axios');

  var screen = {
    method: 'get',
    url: 'https://brandaway.fastech.pk/api/Login/MyTypes',
  };
  
  axios(screen)
  .then( (response) => {
    console.log(response.data);

    //  this.data  =  response.data;
     //console.log( response.data);

    this.types = response.data.data;
   

  //  this.rows = response.data.data;

     console.log(this.myProducts);
     console.log(response.data);
 })
  .catch(function (error) {
    console.log(error);
  });
    
    window.addEventListener('resize', this.initTrHeight);
  },
  mounted() {
    this.initTrHeight();
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  mixins: [heightTransition],
  data() {
    return {
      FinishButton:'Submit',
      busy:false,
      sig:'',
      paymentScreen:true,
      amount:'',
       pubKey:
        "pk_test_51K7vpSBWFhPZDTbf5VjL9kRYQn7VqgS08mj0WoEZZyLnDOc39k9HzLgjY1TokLLz5fiHHkXOrmn5C7y0xFI0LXtV005DMXJsGZ",
      mybool:false,
      tooltip:'',
      selected:[],
      types:[],
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)',
      },
      disabled: false,
      dataUrl: 'https://avatars2.githubusercontent.com/u/17644818?s=460&v=4',

      ids: [],
      licenses: [],
      samples: [],
      activeAvatar1: 'light-danger',
      activeAvatar2: 'light-danger',
      activeAvatar3: 'light-danger',

      active1: false,
      active2: false,
      active3: false,

      preferences: [ {label:'Store',value:'Store' },{label:'Salon',value:'Salon' },{label:'Home business',value:'Hb' }],
      currentDate: moment().format('YYYY-MM-DD'),
      errors: {
        firstName: false,
        lastName: false,
        phoneNumber: false,
        preference: false,
        businessName: false,
        uname: false,
        email: false,
        password: false,
        conpassword: false,
        pname: false,
        pemail: false,
        emiratesId: false,
        bname: false,
        aboutus: false,
        address: false,
        city: false,
        contactNo: false,
        url: false,
        TLicense: false,
        TLicenseEx: false,
        producttype: false,
        profile: false,
        cover: false,
        emiratesids: false,
        tradelicenses: false,
        productsamples: false,
        licenseSource: false,
        logo: false,
      },
      vendorId: 0,
      partners: [
        {
          // id:1,
          vendorId: 0,
          name: '',
          email: '',
          emiratesId: '',
        },
      ],
      myname: '',
      nextTodoId: 2,
      Remail: {
        person: '',
        bakery: '',
        email: '',
      },
      idCard:
        'https://dk0pm9zdlq16s.cloudfront.net/19fd5f70-b269-499e-a127-2d1767a7a182.png',
      license:
        'https://dk0pm9zdlq16s.cloudfront.net/19fd5f70-b269-499e-a127-2d1767a7a182.png',
      psample:
        'https://dk0pm9zdlq16s.cloudfront.net/19fd5f70-b269-499e-a127-2d1767a7a182.png',
      licensefile: '',
      psamplefile: '',
      EmpId: [],
      fileCover: '',
      datenow: '',
      message: 0,
      plus:
        'https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png',
      cover:
        'https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png',
      fileProfile: '',
      times: [],
      value: 0,
      sampleloading: false,
      idloading: false,
      licenseloading: false,

      coverloading: false,
      logoloading: false,
      files: [],
      selectedFiles: undefined,
      //selectedDocs: undefined,
      selectedCity: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      url,
      vendorP: {
        name: '',
        email: '',
        emiratesId: '',
      },
      VendorDoc: {
        id: 0,
        vendorId: '',
        path: '',
        type: '',
      },

      vendorObj: {
        user: {
          id: 0,
          email: '',
          firstName: '',
          lastName: '',
          password: '',
          phoneNo: '',
          role: 'vendor',
        },
        vendor: {
          id: 0,
          business: '',
          logo: '',
          aboutUs: '',
          address: '',
          city: '',
          service: '',
          preferences: [],
          userId: 0,
          checkServices:'',
          typeId:'',
          packageType:'Yearly',
        },
        signature: '',
        ids: [],
        licenses: [],
        samples: [],
      },
      uploadedImagesIDs: [],

      uploadedSampleProducts: [],
      tradeLicenses: [],

      cityName: [
        'Abu Dhabi',
        'Al-Ain',
        'Dubai',
        'Sharjah',
        'Umm al-Qaiwain',
        'Fujairah',
        'Ajman',
        'Ra’s al-Khaimah',
      ],

      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      licenseSourceOptions: ['DED', 'Abu dhabi', 'Ras al khaimah'],
      productTypeOptions: [
        'Ice-cream Cakes',
        'Sponge Cakes',
        'Pound Cake',
        'Butter Cake',
      ],
    };
  },
  methods: {
      ChangePrice(amount,pack)
    {
      this.vendorObj.vendor.packageType = pack;
      console.log(amount);
      if(amount == 3000 && pack == 'monthly' )
      {
        this.amount = 500;
      }
      if(amount == 500 && pack == 'yearly')
      {
        this.amount = 3000;
      }
      if(amount == 12000 && pack == 'monthly' )
      {
        this.amount = 1000;
      }if(amount == 1000 && pack == 'yearly' )
      {
        this.amount = 12000;
      }
    },
    paymentBtn()
    {
var baseUrl = `https://dc.appick.io/stripeBrand`;
        axios.post(baseUrl, { amount: this.amount }).then((res) => {
          console.log(res);
          this.sig = res.data.clientSecret;

          this.$refs.elementRef.submit();
          this.busy = false;
          return true;
        });
    },
   tokenCreated(token) {
      console.log("Token is created...");
      var url = "https://dc.appick.io/stripeBrand/charge";
    
      this.busy = true;
      axios
        .post(url, {
          stripeToken: token.id,
          amount: this.amount,
          about: this.vendorObj.user.email ,
        })
        .then((res) => {
          console.log(res.data.charge);
          try {
            if (res.data.charge.captured) {
      this.vendorObj.user.phoneNo =  "971" +this.vendorObj.user.phoneNo;
       this.vendorObj.vendor.checkServices = this.selected.toString();
      this.vendorObj.vendor.typeId = this.vendorObj.vendor.preferences.id;
      this.vendorObj.vendor.preferences = this.vendorObj.vendor.preferences.name;
 var axios = require('axios');
        var data = this.vendorObj;
        var config = {
          method: 'post',
          url: 'https://brandaway.fastech.pk/api/Login/Register/',
          headers: {
            Authorization:
              'Bearer pKFBCiJ2J0r5BGU-mlOiSN_IWZF7m2Oxl1yv_PskYmT1udlK7seq3djoVgNfgDLJELFwwxdcYd9LQcvH9OVIpYyZTNrUPDuV9sAzC1WNz2SYEt2TIzJpWmzwAQcPbucnq-ALeFSQc6CsrxFhMacr3bc_KL0Cvl4diQUTvChDZVx4NPxZ-VtMXHiLMzvpBAcrxCQ2rF3V5TpBZFDXDBs1EIonYD9GCG9PHu6U-iIAXKAJ3Xi97-S2kqUd5SwXg0ANLKDqYiWHyF2OJev3_V4g48e9ApFAN4zm7nAPvv1vXZW5H-0vxXBZb1WulF-q5Ljp',
            'Content-Type': 'application/json',
          },
          data: data,
        };

        axios(config)
          .then(response => {
            //console.log(response.data.status);
            console.log(response.data);

            if (response.data.status == 'success') {
              console.log(response.data);

              Swal.fire(
                'Registered!',
                'Your request is submitted for approval!',
                'success',
              ).then(res => {
                this.$router.push('/login');
              });
            } else {
              console.log('notworking');
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
             
            
          } catch (error) {
            this.busy = false;
            return this.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Unable to process payment, check card info again.",
            });
          }
        });
    },

    NextPopup(my)
    {
        this.$bvModal.hide("modal-select2");
    },
    Skip()
    {
        this.selected.push("");
        console.log("12345667899",this.selected);
        this.$bvModal.hide("modal-select2");
    },
    ValidateContract()
    {
     if(this.selected.length == 0)
     {
      this.$bvModal.show("modal-select2");
      return new Promise((resolve, reject) => {
          resolve(true);
      });
     }
     else 
     {
return new Promise((resolve, reject) => {
          resolve(true);
      });
     }
    },
    save() {
      var _this = this;
      var png = _this.$refs.signature.save();
      var jpeg = _this.$refs.signature.save('image/jpeg');
      var svg = _this.$refs.signature.save('image/svg+xml');
      this.vendorObj.signature = png;
      this.vendorObj.signature = png;
      console.log('Seepng', this.vendorObj.signature);
      // console.log(png);

      //console.log(jpeg)
      //console.log(svg)
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },
    addWaterMark() {
      var _this = this;
      _this.$refs.signature.addWaterMark({
        text: 'mark text', // watermark text, > default ''
        font: '20px Arial', // mark font, > default '20px sans-serif'
        style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
        fillStyle: 'red', // fillcolor, > default '#333'
        strokeStyle: 'blue', // strokecolor, > default '#333'
        x: 100, // fill positionX, > default 20
        y: 200, // fill positionY, > default 20
        sx: 100, // stroke positionX, > default 40
        sy: 200, // stroke positionY, > default 40
      });
    },
    // fromDataURL(url){
    // 	var _this = this;
    // 	_this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    // },
    handleDisabled() {
      var _this = this;
      _this.disabled = !_this.disabled;
    },
    validationPackage() {
      return new Promise((resolve, reject) => {
        if (this.vendorObj.vendor.service !== '') {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    },
    signatureCheck()
    {
       if(this.selected.length == 0)
     {
      this.$bvModal.show("modal-select2");
     }
        return new Promise((resolve, reject) => {
           var _this = this;
      console.log(_this.$refs.signature.isEmpty());
      var png = _this.$refs.signature.save();
      var jpeg = _this.$refs.signature.save('image/jpeg');
      var svg = _this.$refs.signature.save('image/svg+xml');
      this.vendorObj.signature = png;
      this.vendorObj.signature = png;
      console.log('Seepng', this.vendorObj.signature);
      if (_this.$refs.signature.isEmpty()) 
      {
         this.$bvToast.toast('Please sign the contract.', {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        });
       return resolve(false);
      } 
      else
      {
       return resolve(true);
      }
      });
    },
    validationSignature() {
      return new Promise((resolve, reject) => {
        if (this.vendorObj.signature !== '') {
          return console.log(this.vendorObj);

          resolve(true);
          Swal.fire(
            'Registered!',
            'Your request is submitted for approval!',
            'success',
          ).then(res => {
            this.$router.push('/login');
          });
        } else {
          resolve(false);
        }
      });
    },
    ClickAble1() {
      this.active2 = false;
      this.active3 = false;
      //this.vendorObj.vendor.service = 'Bronze';
      //this.activeAvatar2 == 'light';
      //this.activeAvatar3 == 'light';
      this.active1 = !this.active1;
      if (this.active1) {
        this.vendorObj.vendor.service = 'Bronze';
        this.paymentScreen = false
        this.FinishButton = 'Submit';

      } else {
        this.vendorObj.vendor.service = '';
      }
      console.log(this.vendorObj.vendor.service);

      if (this.activeAvatar1 == 'light') {
        this.activeAvatar1 = 'light-danger';
      } else {
        this.activeAvatar1 = 'light';
      }
      this.activeAvatar3 = 'light-danger';
      this.activeAvatar2 = 'light-danger';

      // this.activeAvatar1 == 'light';
      // if(this.active2 == false && this.active3 == false)
      // {
      // this.active1 = !this.active1;

      // }
    },
    ClickAble2() {
      this.active1 = false;
      this.active3 = false;

      //this.activeAvatar2 == 'light';
      //this.activeAvatar3 == 'light';
      this.active2 = !this.active2;
      if (this.active2) {
        this.vendorObj.vendor.service = 'Silver';
        this.paymentScreen = true;
        this.amount = 3000;
        this.FinishButton = null;

      } else {
        this.vendorObj.vendor.service = '';
      }
      console.log(this.vendorObj.vendor.service);
      //    this.activeAvatar2 == 'light';
      if (this.activeAvatar2 == 'light') {
        this.activeAvatar2 = 'light-danger';
      } else {
        this.activeAvatar2 = 'light';
      }
      this.activeAvatar1 = 'light-danger';
      this.activeAvatar3 = 'light-danger';
      //    if(this.active3 == false && this.active1 == false)
      //   {
      //   this.active2 = !this.active2;
      //  if(this.activeAvatar2 == 'light')
      //   {
      //   this.activeAvatar2 = 'light-danger';
      //   }
      //   else
      //   {
      //   this.activeAvatar2 = 'light';

      //   }
      //}
    },
    ClickAble3() {
      this.active1 = false;
      this.active2 = false;
      // this.vendorObj.vendor.service = 'Gold';
      //this.activeAvatar2 == 'light';
      //this.activeAvatar3 == 'light';
      this.active3 = !this.active3;
      //this.activeAvatar3 == 'light';
      if (this.active3) {
        this.vendorObj.vendor.service = 'Gold';
        this.paymentScreen = true;
        this.amount = 12000;
        this.FinishButton = null;
      } else {
        this.vendorObj.vendor.service = '';
      }
      console.log(this.vendorObj.vendor.service);
      if (this.activeAvatar3 == 'light') {
        this.activeAvatar3 = 'light-danger';
      } else {
        this.activeAvatar3 = 'light';
      }
      this.activeAvatar1 = 'light-danger';
      this.activeAvatar2 = 'light-danger';
      //     if(this.active1 == false && this.active2 == false)
      //     {
      //    this.active3 = !this.active3;
      //    if(this.activeAvatar3 == 'light')
      //     {
      //     this.activeAvatar3 = 'light-danger';
      //     }
      //     else
      //     {
      //     this.activeAvatar3 = 'light';
      //     }
      //  }
    },
    deleteCover() {
      this.vendorObj.cover = '';
      this.coverloading = false;
    },
    deleteLogo() {
      //console.log(0);
      this.vendorObj.logo = '';
      this.logoloading = false;
       
      this.$refs.fileProfile.value=null;
    },
    removeEmirateId(index) {
      console.log(index);
      this.vendorObj.ids.splice(index, 1);
      this.$refs.EmpId.value=null;
      
    },
    removeLicense(index) {
      console.log(index);
      this.vendorObj.licenses.splice(index, 1);
      this.$refs.licensefile.value=null;

    },
    removeSample(index) {
      console.log(index);
      this.vendorObj.samples.splice(index, 1);
    },
    CheckDocs() {
      this.errors.emiratesids = true;
      this.errors.tradelicenses = true;
      this.errors.productsamples = true;
      this.vendorObj.docs.forEach(elem => {
        if (elem.type == 'id') {
          this.errors.emiratesids = false;
        } else if (elem.type == 'license') {
          this.errors.tradelicenses = false;
        } else if (elem.type == 'sample') {
          this.errors.productsamples = false;
        }
      });
      // console.log(this.vendorObj.emiratesids);
      // if (this.vendorObj.emiratesids.length !== 0) {
      //   this.errors.emiratesids = false;
      // } else {
      //   this.errors.emiratesids = true;
      // }
    },
CheckContact() {
      var elem = this.$refs['phone'];
      console.log(elem.state);
      console.log("Helooo.Phoneeee",this.vendorObj.user.phoneNo);

      // var re = /^(?:971|00971|0)?(?:50|51|52|54|55|56|58|2|3|4|6|7|9)\d{7}$/;
      if (
        this.vendorObj.user.phoneNo.length < 9 ||
         this.vendorObj.user.phoneNo == ''
      ) {
         this.errors.phoneNumber = true;
        return elem.state = false;
        //console.log("IOk");
        //this.vendorObj.user.phoneNo =  "971" +this.vendorObj.user.phoneNo;
      
      }
  
      else {
         this.errors.phoneNumber = false;
        return elem.state = true;
      }
    },
    CheckCover() {
      console.log(this.vendorObj.cover);
      if (this.vendorObj.cover !== '') {
        this.errors.cover = false;
      } else {
        this.errors.cover = true;
      }
    },
    CheckProfile() {
      console.log(this.vendorObj.vendor.logo);
      if (this.vendorObj.vendor.logo !== '') {
        console.log('MY1');
        return (this.errors.profile = false);
      } else {
        return (this.errors.profile = true);
      }
    },
    CheckPType() {
      console.log(this.vendorObj.producttype);
      if (this.vendorObj.producttype.length !== 0) {
        this.errors.producttype = false;
      } else {
        this.errors.producttype = true;
      }
    },
    CheckUrl() {
      //console.log("Osama");

      var elem = this.$refs['url'];
      console.log(elem.state);
      console.log(this.vendorObj.url);
      var re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      if (re.test(this.vendorObj.url) || this.vendorObj.url == '') {
        //console.log("IOk");
        elem.state = true;
        this.errors.url = false;
      } else {
        elem.state = false;
        this.errors.url = true;
      }
    },
    CheckPreferences() {
      console.log(this.vendorObj.vendor.preferences);
      if(this.vendorObj.vendor.preferences.isFor == "Products")
      {
      this.tooltip  = "You would be able to sell products on our marketplace.";
      }
      else if (this.vendorObj.vendor.preferences.isFor == "Services")
      {
      this.tooltip  = "You would be able to offer services on our marketplace.";

      }
      else if(this.vendorObj.vendor.preferences.isFor == "Both")
      {
      this.tooltip  = "You would be able to sell products & offer seriveces on our marketplace";
      }
      if (this.vendorObj.vendor.preferences.length !== 0) {
        //console.log("IOk");
        this.errors.preference = false;
      } else {
        this.errors.preference = true;
      }
    },
    CheckDate() {
      // console.log("OSama");

      var elem = this.$refs['TLicenseEx'];
      //console.log(elem);
      //console.log(this.errors.TLicenseEx);
      //console.log(this.vendorObj.tradelicenseExDate);
      //  ||
      console.log(this.currentDate);
      if (
        this.vendorObj.tradelicenseExDate <= this.currentDate ||
        this.vendorObj.tradelicenseExDate == null
      ) {
        console.log('Work');
        //elem.state = false;

        this.errors.TLicenseEx = true;
        console.log(this.errors.TLicenseEx);
      } else {
        // elem.state = true;
        this.errors.TLicenseEx = false;
        console.log(this.errors.TLicenseEx);
      }
    },
    CheckLicense() {
      //console.log(":");
      var elem = this.$refs['Tlicense'];
      console.log(elem.state);
      // console.log(this.vendorObj.tradeLicenseNum);
      if (this.vendorObj.tradeLicenseNum !== '') {
        elem.state = true;
        this.errors.TLicense = false;
      } else {
        elem.state = false;
        this.errors.TLicense = true;
      }
    },

    // CheckContact() {
    //   console.log('Osama');

    //   var elem = this.$refs['contact'];
    //   console.log(elem.state);
    //   console.log(this.vendorObj.contactNo);
    //   var re = /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/;
    //   if (
    //     re.test(this.vendorObj.contactNo) &&
    //     this.vendorObj.contactNo !== ''
    //   ) {
    //     //console.log("IOk");
    //     elem.state = true;
    //     this.errors.contactNo = false;
    //   }
    //   // else if(this.vendorObj.city === 'null' )
    //   // {
    //   // elem.state = false;
    //   // this.errors.city = true;
    //   // }
    //   else {
    //     elem.state = false;
    //     this.errors.contactNo = true;
    //   }
    // },

    CheckCity() {
      console.log('Osama');
      var elem = this.$refs['city'];
      console.log(elem.state);
      console.log(this.vendorObj.vendor.city);

      if (this.vendorObj.vendor.city !== '') {
        //console.log("IOk");
        elem.state = true;
        this.errors.city = false;
      }
      // else if(this.vendorObj.city === 'null' )
      // {
      // elem.state = false;
      // this.errors.city = true;
      // }
      else {
        elem.state = false;
        this.errors.city = true;
      }
    },

    CheckLicenseSource() {
      var elem = this.$refs['licenseSource'];
      console.log(elem.state);
      console.log(this.vendorObj.licenseSource);

      if (this.vendorObj.licenseSource !== null) {
        //console.log("IOk");
        elem.state = true;
        this.errors.licenseSource = false;
      }
      // else if(this.vendorObj.city === 'null' )
      // {
      // elem.state = false;
      // this.errors.city = true;
      // }
      else {
        elem.state = false;
        this.errors.licenseSource = true;
      }
    },
    CheckAddress() {
      var elem = this.$refs['address'];
      console.log(elem.state);
      //elem.state = null;
      if (this.vendorObj.vendor.address !== '') {
        elem.state = true;
        this.errors.address = false;
      } else {
        elem.state = false;
        this.errors.address = true;
      }
    },
    CheckAbout() {
      var elem = this.$refs['about'];
      console.log(elem.state);
      //elem.state = null;
      if (this.vendorObj.vendor.aboutUs !== '') {
        elem.state = true;
        this.errors.about = false;
      } else {
        elem.state = false;
        this.errors.about = true;
      }
    },
    CheckBusinessName() {
      var elem = this.$refs['businessName'];

      console.log(elem.state);
      var namereg = /^[a-zA-Z ]*$/;
      if (
        namereg.test(this.vendorObj.vendor.business) &&
        this.vendorObj.vendor.business !== ''
      ) {
        elem.state = true;
        this.errors.businessName = false;
      } else {
        elem.state = false;
        this.errors.businessName = true;
      }
    },
    checkFirstName() {
      var elem = this.$refs['firstName'];
      var namereg = /^[a-zA-Z ]*$/;
      if (
        namereg.test(this.vendorObj.user.firstName) &&
        this.vendorObj.user.firstName !== ''
      ) {
        elem.state = true;
        this.errors.firstName = false;
      } else {
        elem.state = false;
        this.errors.firstName = true;
      }
    },
    checkLastName() {
      var elem = this.$refs['lastName'];
      var namereg = /^[a-zA-Z ]*$/;
      if (
        namereg.test(this.vendorObj.user.lastName) &&
        this.vendorObj.user.lastName !== ''
      ) {
        elem.state = true;
        this.errors.lastName = false;
      } else {
        elem.state = false;
        this.errors.lastName = true;
      }
    },
    checkEmail() {
      var elem = this.$refs['email'];
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        re.test(String(this.vendorObj.user.email).toLowerCase()) &&
        this.vendorObj.user.email !== ''
      ) {
        elem.state = true;
        this.errors.email = false;
      } else {
        elem.state = false;
        this.errors.email = true;
      }
    },
    checkPhoneNumber() {
      var elem = this.$refs['phoneNumber'];
      //var re = /as/;
      console.log(this.vendorObj.user.phoneNo);
      var myre = /^(?:\+971|00971|0)(?!2)((?:2|3|4|5|6|7|9|50|51|52|55|56)[0-9]{7,})$/;
      if (
        myre.test(this.vendorObj.user.phoneNo) &&
        this.vendorObj.user.phoneNo !== ''
      ) {
        console.log('1');
        elem.state = true;
        this.errors.phoneNumber = false;
      } else {
        elem.state = false;
        console.log('2');

        this.errors.phoneNumber = true;
      }
      // if (this.vendorObj.user.phoneNo !== '')
      // {
      //   elem.state = true;
      //   this.errors.phoneNumber = false;
      // } else {
      //   elem.state = false;
      //   this.errors.phoneNumber = true;
      // }
    },
    checkPassword() {
      var elem = this.$refs['password'];
      var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      if (
        re.test(this.vendorObj.user.password) &&
        this.vendorObj.user.password !== ''
      ) {
        elem.state = true;
        this.errors.password = false;
      } else {
        elem.state = false;
        this.errors.password = true;
      }
    },
    checkCPassword() {
      var elem = this.$refs['cpassword'];
      console.log(this.passwordCon);
      if (
        this.passwordCon == this.vendorObj.user.password &&
        this.passwordCon !== ''
      ) {
        elem.state = true;
        this.errors.conpassword = false;
      } else {
        elem.state = false;
        this.errors.conpassword = true;
      }
    },

    checkLogo() {
      if (this.vendorObj.logo !== '') {
        elem.state = true;
        this.errors.pname = false;
      } else {
        elem.state = false;
        this.errors.pname = true;
      }
    },
    // checkpEmail()
    // {
    //   var elem = this.$refs["pemail"];
    //   var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   if(re.test(String(this.vendorObj.email).toLowerCase()) && this.vendorObj.email !== '' )
    //   {
    //   elem.state = true;
    //   this.errors.email = false;
    //   }
    //   else
    //   {
    //   elem.state = false;
    //   this.errors.email = true;
    //   }

    // },
    // checkpEmirate()
    // {
    //   var elem = this.$refs["emiratesId"];

    //   if(re.test(this.vendorObj.email) && this.vendorObj.email !== '' )
    //   {
    //   elem.state = true;
    //   this.errors.email = false;
    //   }
    //   else
    //   {
    //   elem.state = false;
    //   this.errors.email = true;
    //   }

    // },
    addItem(vendorId) {
      for (var i = 0, len = this.partners.length; i < len; ++i) {
        this.partners[i].vendorId = vendorId;
        // this.originalSizearr.push(this.cakesizeList[i]);
      }
    },

    Addpartners() {
      this.$refs.wizard.nextTab().then({});
    },
    handleValidation(isValid, tabIndex) {
      console.log(isValid, tabIndex);
      //handle your logic based on the validation result
    },

    repeateAgain() {
      this.partners.push({
        // id: this.nextTodoId += this.nextTodoId,
        name: this.partners.name,
        email: this.partners.email,
        emiratesId: this.partners.emiratesId,
        vendorId: this.vendorId,
      });
      console.log(this.partners);
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });

      //  console.log(this.vendorP);
      //  this.vendorObj.partners.push(this.vendorP);
      //  console.log(this.vendorObj.partners);
      //   this.vendorP.name = '' ;
      //   this.vendorP.email = '' ;
      //   this.vendorP.emiratesId = '' ;

      //  console.log(this.vendorP);
    },
    removeItem(index) {
      this.partners.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    //     call(){
    // this.vendorObj.bakeryName = "Osama";
    // console.log(this.vendorObj.bakeryName );

    //     },
    //     time() {
    //     var self = this
    //     this.datenow = moment().format()
    //     setTimeout(self.time, 1000) // recursive!
    // },
    //        callFunction: function () {
    //             var v = this;
    //             setTimeout(function () {
    //                 v.message++;
    //             }, 3000);

    //             console.log(v.message);
    //         },
    selectlogo() {
      // console.log("Osama");

      this.fileProfile = this.$refs.fileProfile.files[0];
      this.logoloading = true;
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== '') {
        let formData = new FormData();
        formData.append('file', this.fileProfile);
        //console.log(this.fileProfile);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(e => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            // console.log(fn);
            this.plus = fn1;
            this.vendorObj.vendor.logo = this.plus;
            //
            console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = 'loaded';
            this.CheckProfile();
            //  console.log(this.loading);
          });
      }
    },
    selectCover() {
      // console.log("Osama");
      this.coverloading = true;
      this.fileCover = this.$refs.fileCover.files[0];

      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileCover !== '') {
        let formData = new FormData();
        formData.append('file', this.fileCover);
        //console.log(this.fileProfile);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(e => {
            console.log(e.data);
            // this.file = undefined;
            var fn2 = e.data.myresp[0].path;
            // console.log(fn);
            this.cover = fn2;
            this.vendorObj.cover = this.cover;
            //
            console.log(this.vendorObj.cover);
            //console.log(this.myObj.coverImg);
            this.coverloading = 'loaded';

            //  console.log(this.loading);
          });
      }
    },
    EmeratesId() {
      console.log(this.$refs.EmpId.files.length);
      //this.EmpId = this.$refs.EmpId.files;

      console.log(this.$refs.EmpId.files);
      if (this.$refs.EmpId.files.length !== 0) {
        this.idloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.EmpId.files.length; i++) {
          formdata.append('file', this.$refs.EmpId.files[i]);
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch('https://upload.appick.io', requestOptions)
          .then(response => response.json()) //2
          .then(result => {
            console.log(result);
            //this.moreImages.push(result.myresp);
            this.idloading = false;
            console.log('LoadingPic', this.idloading);
            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              var fn = result.myresp[i].path;
              this.vendorObj.ids.push(fn);
            }
             this.CheckEmiratesIds()
            console.log(this.ids, this.idloading);
          })

          .catch(error => console.log('error', error));
      }
    },

    TLicense() {
      if (this.$refs.licensefile.files.length !== 0) {
        this.licenseloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.licensefile.files.length; i++) {
          formdata.append('file', this.$refs.licensefile.files[i]);
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch('https://upload.appick.io', requestOptions)
          .then(response => response.json()) //2
          .then(result => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.licenseloading = false;
              var fn = result.myresp[i].path;
              this.vendorObj.licenses.push(fn);
            }
            this.CheckTradeLicenses();
            console.log(this.licenses);
          })

          .catch(error => console.log('error', error));
      }
      // if (this.licensefile !== "") {
      //   let formData = new FormData();
      //   formData.append("file", this.licensefile);
      //   //console.log(this.fileProfile);
      //   axios
      //   .post("https://upload.appick.io", formData, {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     })
      //     .then((e) => {
      //       console.log(e.data);
      //       // this.file = undefined;
      //       var fn2 =
      //         "https://appick.io/u/lessons/" + e.data.myresp[0].filename;
      //    // console.log(fn);
      //     this.vendorObj.tradelicenses.push(fn2);

      //     //
      //       console.log(this.tradelicenses);
      //       //console.log(this.myObj.coverImg);
      //     this.licenseloading = false;

      //     //  console.log(this.loading);
      //     });
      // }
    },
    PsampleAdd() {
      // console.log("Osama");
      //this.psamplefile = this.$refs.psamplefile.files[0];

      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.$refs.psamplefile.files.length !== 0) {
        this.sampleloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.psamplefile.files.length; i++) {
          formdata.append('file', this.$refs.psamplefile.files[i]);
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch('https://upload.appick.io', requestOptions)
          .then(response => response.json()) //2
          .then(result => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.sampleloading = false;
              var fn = result.myresp[i].path;
              this.vendorObj.samples.push(fn);
            }
            console.log(this.vendorObj.this.samples);
          })

          .catch(error => console.log('error', error));
      }
    },

    AddMoreP() {
      this.times.push(this.value);
      console.log(this.times);
    },

    clearFiles() {
      this.$refs['file-input'].reset();
    },

    fileSelected(e) {
      this.files = e.target.files;
    },
    validateAsync() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    },
    CheckEmiratesIds() {
      if (this.vendorObj.ids.length == 0) {
        this.errors.emiratesids = true;
      } else {
        this.errors.emiratesids = false;
      }
    },
    CheckTradeLicenses() {
      if (this.vendorObj.licenses.length == 0) {
        this.errors.tradelicenses = true;
      } else {
        this.errors.tradelicenses = false;
      }
    },
    CheckProductSamples() {
      if (this.vendorObj.samples.length == 0) {
        this.errors.productsamples = false;
      } else {
        this.errors.productsamples = false;
      }
    },
    validationDocs() {
      return new Promise((resolve, reject) => {
        //  return  resolve(true);
        this.errors.emiratesids = false;
        this.errors.tradelicenses = false;
        console.log(this.vendorObj.vendor.preferences);
        console.log('Osama');
        if(this.vendorObj.vendor.preferences.reqDocs == 'EmiratesId/Passport')
        {
          console.log("Working if ");
        this.CheckEmiratesIds();
        if (
          this.errors.emiratesids !== false 
        ) {
          reject(true);
          return this.$bvToast.toast('Please fill the form correctly.', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
        } else {
          console.log('resoleve');
          resolve(true);
        }
        }
        else if(this.vendorObj.vendor.preferences.reqDocs == 'TradeLicense')
        {
            this.CheckTradeLicenses();

        if (
          this.errors.tradelicenses !== false
          
        ) {
          reject(false);
          return this.$bvToast.toast('Please fill the form correctly.', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
        } else {
          console.log('resoleve');
          resolve(true);
        }
        }
        else if(this.vendorObj.vendor.preferences.reqDocs == 'Both')
        {
          this.CheckTradeLicenses();
        this.CheckEmiratesIds();


        if (
          this.errors.tradelicenses !== false || 
          this.errors.emiratesids !== false 
        ) {
          reject(true);
          return this.$bvToast.toast('Please fill the form correctly.', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
        } else {
          console.log('resoleve');
          resolve(true);
        }
        }
      
      });
    },
    formSubmitted() {
      console.log('Hello Boss', this.vendorObj);
      // this.vendorObj.vendor.checkServices = this.selected.toString();
      // this.vendorObj.user.phoneNo =  "971" + this.vendorObj.user.phoneNo;
      // this.vendorObj.vendor.typeId = this.vendorObj.vendor.preferences.id;
      // this.vendorObj.vendor.preferences = this.vendorObj.vendor.preferences.name;
      console.log("Hello",this.vendorObj.vendor.preferences);
      //return console.log(this.vendorObj.vendor);
      var _this = this;
      console.log(_this.$refs.signature.isEmpty());
      var png = _this.$refs.signature.save();
      var jpeg = _this.$refs.signature.save('image/jpeg');
      var svg = _this.$refs.signature.save('image/svg+xml');
      this.vendorObj.signature = png;
      this.vendorObj.signature = png;
      console.log('Seepng', this.vendorObj.signature);

 

      if (_this.$refs.signature.isEmpty()) {
        return this.$bvToast.toast('Please sign the contract.', {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        });
      }
      else if(this.selected.length == 0)
      {
      this.$bvModal.show("modal-select2");
      }
       else {
        // this.vendorObj.vendor.preferences = this.vendorObj.vendor.preferences.join();
        if(this.paymentScreen == true)
        {
          return this.$bvToast.toast('Please pay the amount of AED ' + this.amount, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        });
        }
        else
        {
          console.log('Hello Boss', this.vendorObj);
      this.vendorObj.vendor.checkServices = this.selected.toString();
      this.vendorObj.user.phoneNo =  "971" + this.vendorObj.user.phoneNo;
      this.vendorObj.vendor.typeId = this.vendorObj.vendor.preferences.id;
      this.vendorObj.vendor.preferences = this.vendorObj.vendor.preferences.name;
      this.vendorObj.vendor.packageType = "Monthly";
      console.log("Hello",this.vendorObj.vendor.preferences);
 var axios = require('axios');
        var data = this.vendorObj;
        var config = {
          method: 'post',
          url: 'https://brandaway.fastech.pk/api/Login/Register/',
          headers: {
            Authorization:
              'Bearer pKFBCiJ2J0r5BGU-mlOiSN_IWZF7m2Oxl1yv_PskYmT1udlK7seq3djoVgNfgDLJELFwwxdcYd9LQcvH9OVIpYyZTNrUPDuV9sAzC1WNz2SYEt2TIzJpWmzwAQcPbucnq-ALeFSQc6CsrxFhMacr3bc_KL0Cvl4diQUTvChDZVx4NPxZ-VtMXHiLMzvpBAcrxCQ2rF3V5TpBZFDXDBs1EIonYD9GCG9PHu6U-iIAXKAJ3Xi97-S2kqUd5SwXg0ANLKDqYiWHyF2OJev3_V4g48e9ApFAN4zm7nAPvv1vXZW5H-0vxXBZb1WulF-q5Ljp',
            'Content-Type': 'application/json',
          },
          data: data,
        };

        axios(config)
          .then(response => {
            //console.log(response.data.status);
            console.log(response.data);

            if (response.data.status == 'success') {
              console.log(response.data);

              Swal.fire(
                'Registered!',
                'Your request is submitted for approval!',
                'success',
              ).then(res => {
                this.$router.push('/login');
              });
            } else {
              console.log('notworking');
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
       
      }
      //router.push("auth-login");
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.errors.firstName = false;
        this.errors.lastName = false;
        this.errors.email = false;
        this.errors.phoneNumber = false;
        this.errors.preference = false;
        this.errors.password = false;

        this.CheckPreferences();
        this.checkFirstName();
        this.checkLastName();
        this.checkPassword();
        this.checkCPassword();
        this.checkEmail();
        this.CheckContact();

        if (
          this.errors.firstName !== false ||
          this.errors.lastName !== false ||
          this.errors.email !== false ||
          this.errors.phoneNumber !== false ||
          this.errors.preference !== false ||
          this.errors.password !== false ||
          this.errors.conpassword !== false
        ) {
          this.$bvToast.toast('Please fill the form correctly.', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
          console.log(this.vendorObj);
          reject();
        } else {
          console.log(this.vendorObj);
          var axios = require('axios');

          var config = {
            method: 'get',
            url:
              'https://brandaway.fastech.pk/api/Login/CheckEmail/' +
              this.vendorObj.user.email,
          };

          axios(config)
            .then(response => {
              // console.log(JSON.stringify(response.data));

              //  this.data  =  response.data;
              console.log(response.data);
              if (response.data.status == 'success') {
                resolve(true);
              } else if (response.data.status == 'error') {
                this.$bvToast.toast(
                  'This email address is already registerd!',
                  {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true,
                  },
                );
                reject();
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    validationFormBakeryDetails() {
      return new Promise((resolve, reject) => {
        //resolve(true);
        console.log(this.vendorObj.vendor.preferences.isFor);
        if(this.vendorObj.vendor.preferences.isFor == 'Both')
        {
        this.errors.profile = false;
        }
        else
        {
        this.errors.profile = false;
        this.CheckProfile();
        }
       
        this.errors.businessName = false;
        this.errors.aboutus = false;
        this.errors.address = false;
        this.errors.city = false;

        this.CheckBusinessName();
        this.CheckAbout();
        this.CheckAddress();
        this.CheckCity();

        if (
          this.errors.aboutus !== false ||
          this.errors.businessName !== false ||
          this.errors.address !== false ||
          this.errors.city !== false ||
          this.errors.profile !== false
        ) {
          this.$bvToast.toast('Please fill the form correctly.', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });

          reject();
          console.log(this.vendorObj);
        } else {
          console.log(this.vendorObj);
          resolve(true);
        }
        //resolve(true);
      });
    },
    validationFormUploadDoc() {
      return new Promise((resolve, reject) => {
        if (s) {
          resolve(true);
        } else {
          reject();
        }
      });
    },
  },
};
</script>
<style>
.initial {
  color: #f88379;
}
.clickcard {
  background-color: #f88379;
  color: white;
}
.card {
  transition: 0.3s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
